import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseRoutes } from '@nts/std';
import { environment } from 'src/environments/environment';

const title = environment ? environment.appTitle : 'Application Title';
const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        'main',
        () => import('./main/main.module').then(m => m.MainModule)
      ),
      ...BaseRoutes.getEntityRoutes(
        title,
        'persistent',
        () => import('./persistent/persistent.module').then(m => m.PersistentModule)
      ),
      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      )
      // {
      //   path: 'demos', component: DemosComponent,
      //   data: {
      //     title: 'Components Demo'
      //   }
      // }
    ]
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    // RouterModule.forRoot(routes2),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
