import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
// TODO Proviamo a metterli in un modulo a parte
import { StdModule, NtsAppRootModule, NtsBootService } from '@nts/std';
import { environment } from 'src/environments/environment';
import { environment as devEnvironment } from 'src/environments/environment.dev';

import { FormsModule } from '@angular/forms';
import { MainModule } from './main/main.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EnvironmentConfiguration, UNPROXIED_ENVIRONMENT, unproxiedEnvironmentFactory } from '@nts/std/environments';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    StdModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    // StdModule.forRoot(),
  ],
  providers: [
    {
      provide: EnvironmentConfiguration,
      useValue: { ...environment }
    },
    {
      provide: UNPROXIED_ENVIRONMENT,
      useFactory: unproxiedEnvironmentFactory(devEnvironment),
      deps: [EnvironmentConfiguration]
    },
    // E' importante che prima vengono dichiarati i root providers di std
    // Tra cui l'http interceptor, altrimenti non si ha il bearer token nei mock
    ...StdModule.getRootProviders(),
    ...MainModule.getRootProviders(),

    // Abilita modalità mock per auth
    // { provide: AUTH_MOCK_MODE, useValue: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule extends NtsAppRootModule {
  constructor(
    bootService: NtsBootService
  ) {
    super(
      bootService
    );

    // Se si vuole si può sovrascrivere il token con uno valido
    // authService.setAccessToken('MOCK_ACCES_TOKEN');
    // authService.setRefreshToken('MOCK_REFRESH_TOKEN');

  }
}
