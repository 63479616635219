import { LogLevel } from '@nts/std/utility';
import { EnvironmentConfiguration } from '@nts/std/environments';

export const environment: EnvironmentConfiguration = {
  production: true,
  baseAppUrl: 'https://ntsframeworkwebapp.ntsinformatica.it',
  authenticationAppUrl: 'https://ntsloginservice.ntsinformatica.it/auth/login',
  prefixApi: '/api',
  envType: 'DEV',
  appTitle: 'Test - DEV',
  appSubTitle: '',
  logLevel: LogLevel.All,
  isEnterpriseBarrierRequired: false,
};
